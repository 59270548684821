import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCaretRight,
    faCaretDown,
    faMapMarkerAlt,
    faPhoneAlt,
    faEnvelope,
    faUpload,
    faChevronLeft,
    faChevronRight,
    faGlobeAfrica,
    faTimes,
    faSearch,
    faAngleRight,
    faAngleLeft
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faCaretRight,
    faCaretDown,
    faMapMarkerAlt,
    faPhoneAlt,
    faEnvelope,
    faUpload,
    faChevronLeft,
    faChevronRight,
    faTimes,
    faGlobeAfrica,
    faSearch,
    fab,
    faAngleRight,
    faAngleLeft
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
