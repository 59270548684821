// FIXME: rebuild this mess

// Mobile Devices
window.onload = function() {
  if (window.innerWidth <= 768) {
    document.addEventListener('scroll', function() {
      const top =
        (document.documentElement && document.documentElement.scrollTop) ||
        document.body.scrollTop ||
        window.pageYOffset;
      if (top != 0) {
        document
          .querySelector('#main-header')
          .setAttribute('style', 'height: 85px;');
        document
          .querySelector('.bm-burger-button')
          .setAttribute('style', 'top: 27px;');
      } else {
        document.querySelector('#main-header').removeAttribute('style');
        document.querySelector('.bm-burger-button').removeAttribute('style');
      }
    });
    document.addEventListener('touchmove', function() {
      const top =
        (document.documentElement && document.documentElement.scrollTop) ||
        document.body.scrollTop ||
        window.pageYOffset;
      if (top != 0) {
        document
          .querySelector('#main-header')
          .setAttribute('style', 'height: 85px;');
        document
          .querySelector('.bm-burger-button')
          .setAttribute('style', 'top: 27px;');
      } else {
        document.querySelector('#main-header').removeAttribute('style');
        document.querySelector('.bm-burger-button').removeAttribute('style');
      }
    });
  }
};

// Desktop
window.onload = function() {
  if (window.innerWidth > 768) {
    document.addEventListener('scroll', function() {
      const top =
        (document.documentElement && document.documentElement.scrollTop) ||
        document.body.scrollTop ||
        window.pageYOffset;
      if (top != 0) {
        // document
        //   .querySelector('#header-art')
        //   .setAttribute(
        //     'style',
        //     'height: 100%; max-width: 200px; padding: 5px 0;'
        //   );
        // document
        //   .querySelector('#header-logo > a > picture > img')
        //   .setAttribute('style', 'max-width: 85px;');
        document
          .querySelector('#main-header')
          .setAttribute(
            'style',
            'height: 100px; box-shadow: 0 2px 4px 1px rgba(32, 32, 32, 0.4);'
          );
      } else {
        document.querySelector('#main-header').removeAttribute('style');
        // document.querySelector('#header-art').removeAttribute('style');
        document
          .querySelector('#header-logo > a > picture > img')
          .removeAttribute('style');
      }
    });
  }
};
