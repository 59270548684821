import axios from "axios";
import { Model } from "vue-api-query";
import { setupCache } from "axios-cache-adapter";

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

let lang = null;
if (document.cookie.match("(^|;)\\s*" + "pc-lang" + "\\s*=\\s*([^;]+)")) {
  lang =
    document.cookie.match("(^|;)\\s*" + "pc-lang" + "\\s*=\\s*([^;]+)").pop() ||
    "";
} else {
  lang = "en";
}
console.log(lang);
const api = axios.create({
  adapter: cache.adapter,
  headers: {
    "Cache-Control": "no-cache",
    common: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/x-www-form-urlencoded",
      "Accept-Language": localStorage.getItem("Language") || "en-gb",
      "X-Accept-Language": localStorage.getItem("Language") || "en-gb",
      "X-localization": lang,
    },
  },
  baseURL: process.env.VUE_APP_BASE_URL,
});

axios.defaults.withCredentials = true;

// Form Backend Validation Global Registry
window.Form = require("form-backend-validation").Form;

// CSRF for submitting forms
axios.get("https://premiumcard.net/sanctum/csrf-cookie").then((response) => {
  let token = document.cookie["XSRF-TOKEN"];
  api.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
  axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
});

Model.$http = api;
window.axios = api;
