import Vue from 'vue';
import * as Sentry from "@sentry/vue";
// import * as Sentry from '@sentry/browser';
// import * as Integrations from '@sentry/integrations';

// Sentry.init({
//   environment: process.env.MIX_ENV,
//   dsn: process.env.MIX_SENTRY_DSN_PUBLIC,
//   integrations: [
//     new Integrations.Vue({
//       Vue,
//       attachProps: true,
//       logErrors: true
//     })
//   ]

  Sentry.init({
    Vue: Vue,
    environment: process.env.APP_ENV,
    dsn: process.env.MIX_SENTRY_DSN_PUBLIC,
    attachProps: true,
    logErrors: true,
});
