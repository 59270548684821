<template>
  <router-link
    :to="url"
    data-aos="fade-up"
    class="group h-28 sm:h-36 md:h-48 xl:h-64 2xl:h-80 relative"
  >
    <div class="relative">
      <div class="aspect-ratio-square w-full"></div>

      <div class="absolute w-full h-full p-2 fig-parent top-0 transition-all">
        <figure
          class="
            bg-white
            w-full
            rounded-md
            md:rounded-10
            h-full
            flex
            justify-center
            items-center
            shadow-none
            md:shadow-img
          "
        >
          <img
            :alt="title"
            :src="image"
            :title="title"
            class="h-auto md:h-full w-full absolute rounded-md md:rounded-10"
            :class="'md:p-' + padding"
          />
        </figure>
        <div
          v-text="title"
          class="
            text-center
            uppercase
            group-hover:opacity-100
            transition-all
            font-bold
            break-words
            mt-4
            md:block
            hidden
            title
          "
          :class="$lang == 'ar' ? 'xl:text-base text-sm' : 'text-lg'"
        ></div>
      </div>
    </div>

    <span
      v-text="title"
      class="
        text-center text-2
        sm:text-12
        capitalize
        w-full
        block
        md:hidden
        font-normal
        break-words
        mt-2
        title-mobile
      "
    ></span>
  </router-link>
</template>

<script>
export default {
  name: "GridItem",
  props: {
    title: {
      type: String,
      required: false,
    },
    image: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    padding: {
      type: Number,
      required: false,
      default: 0,
    },
  },
};
</script>


