import AOS from 'aos';
import 'aos/dist/aos.css';

export const mixin = {
    created() {
        AOS.init({
            useClassNames: false,
            once: true,
            duration: 800
        });
    },
    destroyed() {
        AOS.refresh();
    },
};