<template>
  <div id="app" :class="$lang == 'ar' ? 'font-arabic' : 'font-sans'">
    <vue-progress-bar></vue-progress-bar>
    <main-header v-model="query"></main-header>
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
    <router-view/> -->
    <div class="spacer">&nbsp;</div>
    <div id="page-wrap">
      <main id="main-content">
        <transition name="slide-fade" mode="out-in">
          <router-view :query="query" :key="$route.fullPath"> </router-view>
        </transition>
      </main>
    </div>
    <main-footer></main-footer>
    <sub-footer></sub-footer>
  </div>
</template>

<script>
import MainHeader from "./modules/layouts/components/MainHeader.vue";
import MainFooter from "./modules/layouts/components/MainFooter.vue";
import SubFooter from "./modules/layouts/components/SubFooter.vue";
import SearchResult from "./modules/layouts/components/SearchResult.vue";

export default {
  components: {
    MainHeader,
    MainFooter,
    SearchResult,
    SubFooter,
  },
  data() {
    return {
      query: "",
    };
  },
};
</script>

<style lang="scss"></style>
