<template>
  <div
    class="bg-[#404040] h-[55px] md:h-[70px] flex items-center justify-center"
  >
    <div class="md:text-[14px] text-[10px] text-center mx-2 md:mx-0">
      {{ $t("footer.premInternational") }}
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
