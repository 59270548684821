<template>
    <div class="text-xl" style="font-weight:600">
            <span @click="changeLang('en')" :class="{'text-main' : $lang == 'en'}" class="cursor-pointer">En</span>
            /
            <span @click="changeLang('ar')" :class="{'text-main' : $lang == 'ar'}" class="cursor-pointer">Ar</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showMenu: false
        }
    },
    methods: {
        changeLang(lang) {
            this.$cookies.set('pc-lang', lang);
            window.location.reload();
        }
    }
}
</script>

<style>

</style>