<template>
  <Push
    @closeMenu="handleCloseMenu"
    @openMenu="handleOpenMenu"
    class="block md:hidden slide-sidenav z-50"
    disableEsc
    :closeOnNavigation="true"
    noOverlay
    right
    width="-300"
  >
    <span class="slide-sidenav--item">
      <router-link class="text-lg leading-6 font-semibold" exact to="/">{{
        $t("header.home")
      }}</router-link>
    </span>
    <span class="slide-sidenav--item">
      <router-link class="text-lg leading-6 font-semibold" to="/network">{{
        $t("header.stores")
      }}</router-link>
    </span>
    <span class="slide-sidenav--item">
      <router-link class="text-lg leading-6 font-semibold" to="/offers">{{
        $t("header.offers")
      }}</router-link>
    </span>
    <span class="slide-sidenav--item">
      <router-link
        class="text-lg leading-6 font-semibold"
        to="/apply-for-card"
        >{{ $t("header.apply") }}</router-link
      >
    </span>

    <span class="slide-sidenav--item">
      <router-link class="text-lg leading-6 font-semibold" to="/about">{{
        $t("footer.aboutus")
      }}</router-link>
    </span>
    <span class="slide-sidenav--item">
      <router-link class="text-lg leading-6 font-semibold" to="/contact">{{
        $t("footer.contactus")
      }}</router-link>
    </span>
    <span class="slide-sidenav--item">
      <router-link to="/experience">{{
        $t("footer.experiencepc")
      }}</router-link>
    </span>
    <span class="slide-sidenav--item">
      <router-link class="text-lg leading-6 font-semibold" to="/faq">{{
        $t("footer.faq")
      }}</router-link>
    </span>
    <span class="slide-sidenav--item">
      <router-link class="text-lg leading-6 font-semibold" to="/careers">{{
        $t("footer.careers")
      }}</router-link>
    </span>
    <span class="slide-sidenav--item">
      <lang-option />
    </span>
  </Push>
</template>

<script>
import { Push } from "vue-burger-menu";
import LangOption from "./LangOption.vue";

// TODO: rewrite this mess

export default {
  name: "Sidenav",
  data() {
    return {
      open: false,
    };
  },
  components: {
    Push,
    LangOption,
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    handleOpenMenu() {
      document.querySelector("#main-header") &&
        document
          .querySelector("#main-header")
          .setAttribute("style", "position: static;");

      document.querySelector(".bm-menu") &&
        document
          .querySelector(".bm-menu")
          .setAttribute("style", "display: block; width:340px ");
      document.querySelector("#page-wrap").classList.add("blur-2xl");
    },
    handleCloseMenu() {
      document.querySelector("#main-header") &&
        document.querySelector("#main-header").removeAttribute("style");

      document.querySelector(".bm-menu") &&
        document
          .querySelector(".bm-menu")
          .setAttribute("style", "display: none;");
      document.querySelector("#page-wrap").classList.remove("blur-2xl");
    },
  },
};
</script>

<style lang="scss">
.bm-menu {
  background: #121212 !important;
}
.bm-menu[style="display: none ; width: 340px !important;"] {
  @apply h-full;
  box-shadow: 350px 0px 2px 125px rgba(0, 0, 0, 0.5);
}
.bm-burger-button {
  height: 30px;
  transition: top 0.4s ease;
  width: 40px;
  top: 35px;
  right: 15px !important;
  cursor: pointer;
  background: transparent;
  border-radius: 0.25rem;
  z-index: 10;
  position: absolute !important;
}
.bm-burger-bars {
  @apply bg-gold mx-auto h-3px;
  // background: #b9971a;
  background: white !important;
  &:nth-child(1) {
    top: 25% !important;
  }
  &:nth-child(2) {
    top: 45% !important;
  }
  &:nth-child(3) {
    top: 65% !important;
  }
}
.line-style {
  @apply rounded h-3px;
  width: 27px !important;
  height: 2.85px !important;
}
.bm-cross {
  background: white;
  width: 27px !important;
  height: 2.85px !important;
}
.cross-style {
  right: 18.5px !important;
  top: 30px !important;
}
.bm-item-list {
  @apply w-full pl-12 ml-0;
  background: #121212;
  a {
    span {
      transition: color 0.3s ease;
    }
    &:hover {
      color: #c69e00;
    }
  }
  .router-link-exact-active {
    color: #c69e00;
  }
}
.bm-item-list > * {
  padding: 0.2em;
}

.rtl {
    .bm-item-list {
      @apply pr-12 pl-0;
    }
}
</style>




