<template>
  <div
    class="
      search-result
      bg-black-darkest
      min-h-250
      mx-10
      md:mx-12
      lg:mx-14
      xl:mx-16
    "
  >
    <Grid
      :query="query"
      v-if="showVendors"
      :search="true"
      class="mb-8 min-h-1/2-screen"
    />
  <div v-if="!showVendors" class="w-full h-100 flex justify-center items-center"> {{$t('home.search')}}</div>
  </div>
</template>

<script>
import Grid from "../../Vendors/components/Grid.vue";
export default {
  components: {
    Grid,
  },
  props: {
    query: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showVendors() {
      return this.query.search;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-result {
  .vendors-grid {
    .group {
      @apply h-20 sm:h-36  xl:h-48 2xl:h-60;
    }
  }
}
</style>