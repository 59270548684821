<template>
  <div>
    <GridCategories v-if="showCategories && !search" class="categories-grid md:ml-0 "/>
    <GridVendors
      v-else
      :query="query"
      :category="category"
      class="vendors-grid"
    />
  </div>
</template>

<script>
import GridCategories from "./GridCategories";
import GridVendors from "../components/GridVendors.vue";

export default {
  name: "Grid",
  components: {
    GridVendors,
    GridCategories,
  },
  props: {
    query: {
      type: Object,
      required: true,
    },
    category: {
      type: Object,
      required: false,
    },
    search: {
      type: Boolean,
      required: true,
    },

    filter: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    showCategories() {
      return !(
        this.query.location ||
        this.query.chip ||
        (this.category && this.category.id)
      );
    },
  },
};
</script>

<style lang="scss" >
@media only screen and (max-width: 572px) {
  .vendors-grid {
    section {
      // margin-left: -10px !important;
      // margin-right: -10px !important;
      .group {
        @apply h-20;
          margin-bottom: 8px !important;
        .fig-parent {
          @apply p-0 ;
        }
        figure {
          width: 55px !important;
          height: 55px !important;
          img {
            width: 35px;
            height: 35px;
          }
        }
        .title-mobile {
          @apply hidden;
        }
      }
    }
  }
}
</style>
