<template>
    <div>
        <section class="grid grid-cols-4 md:grid-cols-6 gap-x-2 xl:mt-10 md:mt-8 mt-6">
            <GridCategory
                    class=" overflow-hidden w-4/5"
                    v-for="category in items"
                    :key="category.id"
                    :category="category"
            />
        </section>
        <infinite-loading @infinite="loader" ref="loader">
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
    </div>
</template>

<script>
    import GridCategory from "./GridCategory";
    import VendorCategory from "../models/VendorCategory";

    export default {
        name: "GridCategories",
        components: {GridCategory},
        data() {
            return {
                items: [],
                pageNumber: 1,
                perPage: 20,
            }
        },
        methods: {
            async loader($state) {
                let apiQuery = this.buildQuery();

                let items = await apiQuery.$get();
                this.items.push(...items);

                if (items.length < this.perPage) {
                    $state.complete();
                } else {
                    $state.loaded();
                    this.pageNumber++;
                }
            },
            buildQuery() {
                let item = VendorCategory.include();

                item.page(this.pageNumber);
                item.limit(this.perPage);

                return item;
            },
        },
    }
</script>

