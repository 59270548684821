<template>
  <div class="flex items-stretch relative">
    <input
      @focus="inputFocus"
      type="search"
      ref="query"
      id="vendor-search-input"
      :placeholder="$t('stores.search')"
      class="
        px-5
        rounded
        md:bg-grey-darker-1
        bg-transparent
        flex-grow
        text-grey-light-04
        appearance-none
        focus:outline-none
        lg:w-96
        w-32
        h-10
        font-semibold
        xl:text-lg
        md:text-13
        placeholder:xl:text-lg placeholder:md:text-13
        md:w-44
        focus:2xl:w-280 focus:xl:w-232 focus:md:w-152
        placeholder:md:text-grey-light-04 placeholder:text-transparent
      "
      :class="$lang == 'ar' ? 'md:ml-3' : 'md:mr-3'"
      @keyup="search"
      :value="value"
    />
    <button
      id="close-search"
      @click="clear"
      class="bg-transparent hidden rounded p-2"
    >
      <font-awesome-icon
        class="md:text-lg text-sm text-grey-light-04"
        icon="times"
      />
    </button>
    <button
      @click="search"
      id="searching-btn"
      title="search-btn"
      class="bg-transparent rounded p-2 absolute search-btn right-4 top-0.5"
    >
      <font-awesome-icon
        class="md:text-lg text-lg text-white md:text-grey-light-04"
        icon="search"
      />
    </button>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
export default {
  name: "SearchBox",
  props: ["value"],
  methods: {
    search: debounce(function () {
      if (this.$refs.query) {
        this.$emit("input", this.$refs.query.value);
      }
      if (this.value) {
        document
          .querySelector(".search-btn")
          .classList.add("hidden", "md:inline-block");
        document.querySelector("#close-search").classList.remove("hidden");
        document.querySelector("#close-search").classList.add("md:ml-0");
        document
          .querySelector(".rtl #close-search")
          .classList.add(
            "ml-0",
            "md:ml-0",
            "absolute",
            "top-0",
            "left-24",
            "md:static"
          );
      }
    }, 500),

    inputFocus() {
      this.$refs.query.classList.remove(
        "lg:w-96",
        "md:bg-grey-darker-1",
        "w-32",
        "text-grey-light-04",
        "placeholder:text-transparent"
      );
      this.$refs.query.classList.add(
        "transition-all",
        "duration-500",
        "xl:w-136",
        "sm:w-96",
        "w-84",
        "text-white",
        "bg-grey-light-100",
        "md:bg-transparent",
        "md:rounded-none",
        "rounded-12.5",
        "placeholder:text-grey-light-04"
      );
      document
        .querySelector(".search-btn")
        .classList.add("md:right-12", "right-8");
      document;

      document.querySelector("#close-search").classList.add("inline-block");
      document.querySelector("#close-search").classList.remove("hidden");
      document
        .querySelector(".header-btn")
        .setAttribute("style", "display : none;");
      document
        .querySelector("#main-header ul")
        .setAttribute("style", "display : none;");
      this.$cookies.get("pc-lang") == "ar"
        ? document.querySelector("#main-header").classList.add("pr-2", "pl-4")
        : document
            .querySelector("#main-header")
            .classList.add("2xl:pr-20", "2xl:pl-30", "xl:pr-12", "xl:pl-20");

      document
        .querySelector(".slide-sidenav")
        .setAttribute("style", "display:none");
      document.querySelector("#header-logo").classList.remove("flex");
      document
        .querySelector("#header-logo")
        .classList.add("hidden", "md:inline");

      if (document.querySelector(".rtl .search-btn")) {
        document
          .querySelector(".rtl .search-btn")
          .setAttribute("style", "left:3rem;");
      }
      this.$router.push({ path: "/search" });
    },

    clear() {
      this.$refs.query.classList.remove(
        "transition-all",
        "duration-500",
        "xl:w-136",
        "sm:w-96",
        "w-84",
        "text-white",
        "bg-grey-light-100",
        "md:bg-transparent",
        "md:rounded-none",
        "rounded-12.5",
        "placeholder:text-grey-light-04"
      );
      this.$refs.query.classList.add(
        "lg:w-96",
        "md:bg-grey-darker-1",
        "w-32",
        "transition-all",
        "duration-500",
        "placeholder:text-transparent"
      );
      document
        .querySelector("#close-search")
        .classList.remove("md:inline-block", "md:ml-0");
      if (document.querySelector(".rtl #close-search")) {
        document
          .querySelector(".rtl #close-search")
          .classList.remove(
            "ml-0",
            "md:ml-0",
            "absolute",
            "top-0",
            "left-20",
            "md:static"
          );
      }

      document.querySelector("#close-search").classList.add("hidden");

      document.querySelector(".header-btn").removeAttribute("style");
      document.querySelector("#main-header ul").removeAttribute("style");
      // document.querySelector("#main-header").removeAttribute("style");
      this.$cookies.get("pc-lang") == "ar"
        ? document
            .querySelector("#main-header")
            .classList.remove("pr-2", "pl-4")
        : document
            .querySelector("#main-header")
            .classList.remove("2xl:pr-20", "2xl:pl-30", "xl:pr-12", "xl:pl-20");
      document.querySelector(".slide-sidenav").removeAttribute("style");
      document
        .querySelector(".search-btn")
        .classList.remove("hidden", "md:right-12", "right-8");
      document.querySelector(".search-btn").removeAttribute("style");

      document.querySelector(".search-btn").classList.add("inline-block");
      document.querySelector("#header-logo").classList.add("flex");
      document
        .querySelector("#header-logo")
        .classList.remove("hidden", "md:inline");

      this.$emit("input", "");
      this.$route.name == "merchant" ? "" : this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

@media only screen and (max-width: 767px) {
  #vendor-search-input {
    width: 5rem;
    z-index: 5;

    &:focus {
      width: 18rem;
    }
  }
}
</style>