<template>
  <div
    id="main-header"
    class="
      w-full
      flex
      items-center
      md:justify-between
      justify-between
      md:items-center
      bg-grey-darkest
      z-10
      md:pr-24
    "
  >
    <nav
      class="navbar flex justify-between items-center"
      :class="
        $lang == 'ar' ? 'w-3/5 xl:w-1/2 2xl:w-1/2' : 'w-1/2 lg:w-2/5 2xl:w-1/3'
      "
    >
      <div
        @click="clickLogo"
        id="header-logo"
        class="flex items-center md:w-1/5 ltr:mr-5 rtl:ml-5"
        :class="$lang == 'ar' ? 'text-right' : 'text-left'"
      >
        <router-link to="/" exact>
          <picture>
            <source
              srcset="@/assets/images/header/logo.png"
              media="(min-width: 992px)"
            />
            <img
              class="logo w-full"
              src="@/assets/images/header/logo.png"
              alt="logo"
              title="Premium Card Logo"
            />
          </picture>
        </router-link>
      </div>
      <ul
        class="md:flex hidden justify-between items-center md:w-4/5"
        v-if="this.$route.name != 'passwordReset'"
      >
        <li>
          <router-link
            :to="{ name: 'home' }"
            title="Home"
            exact
            class="md:text-13"
            :class="
              $lang == 'ar'
                ? 'xl:text-xs 2xl:text-base'
                : 'xl:text-base 2xl:text-lg'
            "
            v-text="$t('header.home')"
          ></router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'network' }"
            class="md:text-13"
            :class="
              $lang == 'ar'
                ? 'xl:text-xs 2xl:text-base'
                : 'xl:text-base 2xl:text-lg'
            "
            title="Stores Network"
            v-text="$t('header.stores')"
          ></router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'offers/index' }"
            class="md:text-13"
            :class="
              $lang == 'ar'
                ? 'xl:text-xs 2xl:text-base'
                : 'xl:text-base 2xl:text-lg'
            "
            title="Latest Offers"
            v-text="$t('header.offers')"
          ></router-link>
        </li>

        <li>
          <lang-option />
        </li>
      </ul>
    </nav>
    <div
      class="right-nav flex justify-end items-center"
      :class="
        $lang == 'ar' ? 'w-2/5 xl:w-1/2 2xl:w-1/2' : 'w-1/2 lg:w-3/5 2xl:w-2/3'
      "
    >
      <sidenav></sidenav>
      <SearchBox
        v-model="query.search"
        v-if="this.$route.name != 'passwordReset'"
      />
      <button
        v-if="this.$route.name != 'passwordReset'"
        class="
          bg-gold
          text-white
          px-4
          hidden
          md:block
          ml-2
          rounded-numbers
          font-semibold
          leading-6
          md:text-13 md:w-36
          header-btn
        "
        :class="
          $lang == 'ar'
            ? 'xl:text-xs 2xl:text-base'
            : 'xl:text-base 2xl:text-lg'
        "
      >
        <router-link
          :to="{ name: 'applyForCard' }"
          title="Apply for Card"
          v-text="$t('header.apply')"
        ></router-link>
      </button>
    </div>
  </div>
</template>


<script>
import SearchBox from "@/modules/Home/components/SearchBox.vue";
export default {
  components: { SearchBox },
  data() {
    return {
      query: {},
    };
  },
  watch: {
    query: {
      handler(query) {
        this.$emit("input", query);
      },
      deep: true,
    },
  },

  methods: {
    clickLogo() {
      document.querySelector("#vendor-search-input").value = "";

      document
        .querySelector("#vendor-search-input")
        .classList.remove(
          "transition-all",
          "duration-500",
          "xl:w-136",
          "sm:w-96",
          "w-84",
          "text-white",
          "bg-grey-light-100",
          "md:bg-transparent",
          "md:rounded-none",
          "rounded-12.5",
          "placeholder:text-grey-light-04"
        );
      document
        .querySelector("#vendor-search-input")
        .classList.add(
          "lg:w-96",
          "md:bg-grey-darker-1",
          "w-32",
          "transition-all",
          "duration-500",
          "placeholder:text-transparent"
        );
      document
        .querySelector("#close-search")
        .classList.remove("md:inline-block", "md:ml-0");
      if (document.querySelector(".rtl #close-search")) {
        document
          .querySelector(".rtl #close-search")
          .classList.remove(
            "ml-0",
            "md:ml-0",
            "absolute",
            "top-0",
            "left-20",
            "md:static"
          );
      }

      document.querySelector("#close-search").classList.add("hidden");

      document.querySelector(".header-btn").removeAttribute("style");
      document.querySelector("#main-header ul").removeAttribute("style");
      // document.querySelector("#main-header").removeAttribute("style");
      this.$cookies.get("pc-lang") == "ar"
        ? document
            .querySelector("#main-header")
            .classList.remove("pr-2", "pl-4")
        : document
            .querySelector("#main-header")
            .classList.remove("2xl:pr-20", "2xl:pl-30", "xl:pr-12", "xl:pl-20");
      document.querySelector(".slide-sidenav").removeAttribute("style");
      document
        .querySelector(".search-btn")
        .classList.remove("hidden", "md:right-12", "right-8");
      document.querySelector(".search-btn").removeAttribute("style");

      document.querySelector(".search-btn").classList.add("inline-block");
      document.querySelector("#header-logo").classList.add("flex");
      document
        .querySelector("#header-logo")
        .classList.remove("hidden", "md:inline");
    },
  },
};
</script>


<style scoped lang="scss">
#main-header {
  ul {
    .router-link-exact-active {
      &::after {
        @apply absolute left-0 bottom-0 rounded-12.5;
        background: #c69e00;
        height: 3px;
        @include pseudo;
        opacity: 1;
        width: 20px;
      }
    }
    li {
      @apply relative  inline-block;
      &:hover {
        a {
          color: #c69e00;
        }
      }
    }
  }
  .header-btn {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

@media only screen and (max-width: 1536px) {
  .rtl {
    #main-header {
      .header-btn {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
  }
}

@media only screen and (max-width: 1546px) and (min-width: 1532px) {
  .navbar li a {
    font-size: 16px !important ;
  }
}
</style>