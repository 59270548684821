import Vue from 'vue';
import vSelect from 'vue-select';
import OpenIndicator from '../modules/common/components/icons/VueSelectOpenIndicator';
import Deselect from '../modules/common/components/icons/VueSelectDeselect';

vSelect.props.components.default = () => ({
    OpenIndicator: OpenIndicator,
    Deselect: Deselect,
});

Vue.component('v-select', vSelect);