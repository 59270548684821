import Vue from "vue";
import Router from "vue-router";
import VueMeta from "vue-meta";
import axios from "axios";

Vue.use(Router);
Vue.use(VueMeta);

function getVacancies() {
  let Vacancies = axios
    .get("https://premiumcard.net/api/v2/vacancies")
    .then((res) => {
      let vacancies = res.data.data;
      let vacanciesIds = vacancies.map((element) => element.id);
      return vacanciesIds;
    });
  return Vacancies;
}

function getCategories() {
  let Categories = axios
    .get("https://premiumcard.net/api/v2/vendor_subcategories?page[size]=20")
    .then((res) => {
      let categories = res.data.data;
      let categoriesIds = categories.map((element) => element.id);
      return categoriesIds;
    });
  return Categories;
}

async function getVendors() {
  let availablePages = true;
  let allData = [];
  let currentPage = 0;
  while (availablePages) {
    currentPage++;
    await axios
      .get(
        `https://premiumcard.net/api/v2/vendors?page[size]=40&page[number]=${currentPage}`
      )
      .then((res) => {
        let vendors = res.data.data;
        let vendorsIds = vendors.map((element) => element.id);
        allData.push(...vendorsIds);
      });
    availablePages = currentPage < 40;
  }
  return allData;
}

export const routes = [
  {
    path: "*",
    name: "NotFound",
    component: () =>
      impNotFoundort(
        /* webpackChunkName: "notfound" */ "./modules/common/pages/NotFound"
      ),
    alias: "/not-found",
  },
  {
    path: `/customer/password/reset/:token`,
    name: "passwordReset",
    component: () =>
      import(
        /* webpackChunkName: "PasswordReset" */ "./modules/common/pages/PasswordReset"
      ),
    alias: "/password-reset",
    meta: { sitemap: { ignoreRoute: true } },
  },

  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "./modules/common/pages/Homepage"),
    alias: "/home",
  },

  // faq routes
  {
    path: "/faq",
    name: "faq",
    component: () =>
      import(/* webpackChunkName : FAQ */ "./modules/FAQs/pages/FAQ"),
    alias: "/faqs",
  },

  // conatct routes
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(
        /* webpackChunkName : contanctus */ "./modules/Contact/pages/ContactUs"
      ),

    alias: "/contact-us",
  },

  // card application routes
  {
    path: "/apply-for-card",
    name: "applyForCard",
    component: () =>
      import(
        /* webpackChunkName: "cardapplication" */ "./modules/CardApplications/pages/CardApplication"
      ),
    alias: "/apply",
  },
  {
    path: "/celebrate/:lang",
    name: "celebrate",
    component: () =>
      import(
        /* webpackChunkName: "cfcapplication" */ "./modules/CardApplications/pages/CFCApplication"
      ),
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: "/celebrate",
    redirect: { path: "/celebrate/en" },
  },

  // careers routes
  {
    path: "/careers",
    name: "careers",
    component: () =>
      import(
        /* webpackChunkName: "Careers" */ "./modules/Careers/pages/Careers"
      ),
    alias: "/jobs",
  },

  {
    path: "/careers/:id",
    name: "singleVacancy",
    component: () =>
      import(
        /* webpackChunkName: "SingleVacancy" */ "./modules/Careers/pages/SingleVacancy"
      ),
    meta: {
      sitemap: {
        slugs: () => getVacancies(),
      },
    },
    alias: "/jobs/:id",
  },

  {
    path: "/careers/vacancy/:id",
    name: "vacancyApplication",
    component: () =>
      import(
        /* webpackChunkName: "vacancyapplication" */ "./modules/Careers/pages/VacancyApplication"
      ),
    alias: "/jobs/job/:id",
    meta: {
      sitemap: {
        slugs: () => getVacancies(),
      },
    },
  },

  // layouts routes
  {
    path: "/search",
    name: "search/result",
    component: () =>
      import(
        /* webpackChunkName: "searchresult" */ "./modules/layouts/components/SearchResult"
      ),
    alias: "/searchResult",
  },

  // offers routes
  {
    path: "/offers",
    name: "offers/index",
    component: () =>
      import(/* webpackChunkName : offers */ "./modules/Offers/pages/Offers"),
    alias: "/allOffers",
  },

  // static pages routes
  {
    path: "/about",
    name: "about",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "./modules/StaticPages/pages/About"
      ),

    alias: "/about-us",
  },

  {
    path: "/experience",
    name: "experience",
    component: () =>
      import(
        /* webpackChunkName: "experiencepc" */ "./modules/StaticPages/pages/ExperiencePC"
      ),

    alias: "/exp",
  },

  {
    path: "/privacy_policy",
    name: "privacy-policy",
    component: () =>
      import(
        /* webpackChunkName: "privacypolicy" */ "./modules/StaticPages/pages/PrivacyPolicy"
      ),

    alias: "/privacy-policy",
  },

  // vendors routes
  {
    path: "/network",
    name: "network",
    component: () =>
      import(
        /* webpackChunkName: "storesnetwork" */ "./modules/Vendors/pages/StoresNetwork"
      ),
    alias: "/merchants",
  },

  {
    path: "/network/:category_id/",
    name: "categories/show",
    component: () =>
      import(
        /* webpackChunkName: "storesnetwork" */ "./modules/Vendors/pages/StoresNetwork"
      ),
    alias: "/vendors/:category_id",
    meta: {
      sitemap: {
        slugs: () => getCategories(),
      },
    },
    props: true,
  },

  {
    path: "/network/:parentId/:id",
    name: "vendors/show/vendor",
    component: () =>
      import(
        /* webpackChunkName: "singlevendor" */ "./modules/Vendors/pages/SingleVendor"
      ),
    meta: { sitemap: { ignoreRoute: true } },

    alias: "/vendors/:parentId/:id",
  },

  {
    path: "/network/:parentId/:id",
    redirect: { name: "merchant" },
    meta: { sitemap: { ignoreRoute: true } },
  },

  {
    path: "/merchants/:id",
    name: "merchant",
    component: () =>
      import(
        /* webpackChunkName: "singlevendor" */ "./modules/Vendors/pages/SingleVendor"
      ),
    alias: "/vendor/:id",
    meta: {
      sitemap: {
        slugs: () => getVendors(),
      },
    },
    props: true,
  },
];

export default new Router({
  mode: "history",
  routes: routes,

  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});
