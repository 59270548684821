<template>
    <GridItem
            :image="vendor.images_sizes['207x207']"
            :url="`/merchants/${vendor.id}`"
            :padding="5"
            :id="vendor.id"
    />
</template>

<script>
    import GridItem from "../components/GridItem.vue";

    export default {
        name: "GridVendor",
        components: {GridItem},
        props: {
            vendor: {
                type: Object,
                required: true,
            },
        }
    }
</script>