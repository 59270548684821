import Vue from 'vue';
import VueProgressBar from 'vue-progressbar';
import { progressBar } from '../modules/common/components/mixins/progressBar';

const options = {
    color: '#b9971a',
    failedColor: '#ED1C24',
    thickness: '2px',
    transition: {
        speed: '0.5s',
        opacity: '0.6s',
        termination: 1000
    },
    autoRevert: true,
    location: 'top',
    inverse: false,
    autoFinish: false
};

Vue.use(VueProgressBar, options);
Vue.mixin(progressBar);
