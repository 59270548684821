<template>
    <GridItem
            :image="category.icon_sizes['207x207']"
            :title="category.name"
            :url="`/network/${category.id}`"
    />
</template>

<script>
    import GridItem from "./GridItem";

    export default {
        name: "GridCategory",
        components: {GridItem},
        props: {
            category: {
                type: Object,
                required: true,
            },
        }
    }
</script>