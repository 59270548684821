<template>
  <div>
    <section
      class="grid grid-cols-4 lg:grid-cols-6 gap-x-2 xl:mt-10 md:mt-8 mt-6"
    >
      <GridVendor
        class="overflow-hidden w-full sm:w-4/5"
        :vendor="vendor"
        v-for="vendor in items"
        :key="vendor.id"
      />
    </section>
    <infinite-loading @infinite="loader" ref="loader">
      <span slot="no-results"></span>
      <div slot="no-results" class="w-full text-center" v-if="items.length < 1">
        {{ $t("home.no-result") }}
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import GridVendor from "../components/GridVendor.vue";
import Vendor from "../models/Vendor";
export default {
  name: "GridVendors",
  components: { GridVendor },
  data() {
    return {
      items: [],
      pageNumber: 1,
      perPage: 20,
    };
  },
  props: {
    query: {
      type: Object,
      required: true,
    },
    category: {
      type: Object,
      required: false,
    },
  },
  methods: {
    buildQuery() {
      let item = Vendor.include();
      let query = this.query;

      query.chip && item.where("chip_id", query.chip.id);
      query.location &&
        item.where(query.location.kind, query.location.id.substring(1));
      (query.sort && item.orderBy(query.sort.attribute)) ||
        item.orderBy("order");
      query.search && item.where("name", query.search);
      this.category &&
        this.category.id &&
        item.where("categories", this.category.id);

      item.page(this.pageNumber);
      item.limit(this.perPage);

      console.log(item);
      return item;
    },
    resetQuery() {
      this.items = [];
      this.pageNumber = 1;
      this.$refs.loader.stateChanger.reset();
    },
    async loader($state) {
      let apiQuery = this.buildQuery();

      let items = await apiQuery.$get();
      this.items.push(...items);

      if (items.length < this.perPage) {
        $state.complete();
      } else {
        $state.loaded();
        this.pageNumber++;
      }
    },
  },
  watch: {
    query: {
      async handler(query) {
        this.resetQuery();
      },
      deep: true,
    },
    category(value) {
      this.resetQuery();
    },
  },
};
</script>