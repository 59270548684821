<template>
  <footer
    class="
      w-full
      flex
      justify-between
      items-center
      leading-invisible
      md:static
      relative
      px-28
    "
    id="main-footer"
  >
    <div class="flex copyright">
      <div
        class="text-sm md:text-lg font-semibold flex-1"
        v-html="$t('footer.copyright')"
      ></div>
      <a
        class="designed-by text-sm md:text-lg font-semibold"
        target="_blank"
        href="//34ml.com/"
        title="[34]ML"
        dir="ltr"
        rel="noopener"
        >[34]ML</a
      >
    </div>
    <nav class="hidden md:block">
      <ul>
        <li>
          <router-link
            :to="{ name: 'experience' }"
            title="Experience Premium Card"
            v-text="$t('footer.experiencepc')"
          ></router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'faq' }"
            title="FAQ"
            v-text="$t('footer.faq')"
          ></router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'careers' }"
            title="Careers"
            v-text="$t('footer.careers')"
          ></router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'about' }"
            title="About Us"
            v-text="$t('footer.aboutus')"
          ></router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'contact' }"
            title="Contact Us"
            v-text="$t('footer.contactus')"
          ></router-link>
        </li>
      </ul>
    </nav>
    <div>
      <span>
        <ul id="footer-social-icons">
          <li>
            <a
              href="https://www.facebook.com/premiumcard/"
              title="Facebook"
              target="_blank"
              rel="noopener"
            >
              <img
                src="@/assets/images/footer/Facebook.svg"
                alt="Facebook image"
                title="Facebook"
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/premiumcardeg/"
              title="Instagram"
              target="_blank"
              rel="noopener"
            >
              <img
                src="@/assets/images/footer/Instagram.svg"
                alt="Instagram image"
                title="Instagram"
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/premium-card/"
              title="LinkedIn"
              target="_blank"
            >
              <img
                src="@/assets/images/footer/linkedIn.svg"
                alt="Linkedin image"
                title="LinkedIn"
              />
            </a>
          </li>
        </ul>
      </span>
    </div>
  </footer>
</template>


<script>
export default {};
</script>


<style scoped>
</style>