// TODO: move this mess into place
export const progressBar = {
  created() {
    this.$Progress.start();
    if (this.$router) {
      this.$router.beforeEach((to, _from, next) => {
        if (to.meta.progress !== undefined) {
          let meta = to.meta.progress;
          this.$Progress.parseMeta(meta);
        }
        this.$Progress.start();
        next();
      });
      this.$router.afterEach((_to, _from) => {
        this.$Progress.finish();
      });
    }
  },
  mounted() {
    this.$Progress.finish();
  }
};
