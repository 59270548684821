import './configs/axios'
import './configs/sentry';
import './configs/stickyNavbar';
import './configs/vselect';
import './configs/async-computed';
import './configs/font-awesome';
import './configs/google-maps';
import './configs/vmodal';
import './configs/progress-bar';
import './configs/infinite-loading';
import { mixin as aos_mixin } from './configs/aos';
import './configs/sidenav';
import './configs/masonry';
var _ = require("lodash");
import './assets/css/app.css';
import Vue from 'vue';
import App from './App'
import router from './routes';

Vue.config.productionTip = false;
Vue.config.performance = process.env.MIX_ENV !== 'production';
Vue.config.devtools = process.env.MIX_ENV !== 'production';
Vue.prototype.$smallDevicesBreakpoint = 576;
Vue.prototype.$mobileBreakpoint = 768;
Vue.prototype.$tabletBreakpoint = 992;
Vue.prototype.$desktopBreakpoint = 1200;

// Cookie Setting
import VueCookies from 'vue-cookies'
import LangOption from './modules/common/components/LangOption'
import LangOptionToggle from './modules/common/components/LangOptionToggle'
Vue.component('lang-option', LangOption);
Vue.component('lang-option-toggle', LangOptionToggle);
Vue.use(VueCookies);
if (Vue.$cookies.get('pc-lang')) {
    Vue.prototype.$lang = Vue.$cookies.get('pc-lang');
} else {
    Vue.$cookies.set('pc-lang', 'en');
    Vue.prototype.$lang = 'en'
}
if (Vue.prototype.$lang == 'ar') {
    var root = document.getElementsByTagName('html')[0];
    root.setAttribute('dir', 'rtl');
    document.body.className += ' rtl';
}

// Localization
import VueI18n from 'vue-i18n'
import en from './localization/en.json'
import ar from './localization/ar.json'
Vue.use(VueI18n)

const languages = {
    en: en,
    ar: ar
}
const defaultLocale = Vue.prototype.$lang;
const messages = Object.assign(languages)

var i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: 'ar',
    messages
})

new Vue({ i18n, router, mixins: [aos_mixin], render: h => h(App) })
    .$mount('#app');
