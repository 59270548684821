import {Model as BaseModel} from 'vue-api-query';

export default class Model extends BaseModel {
  // define a base url for a REST API
  baseURL() {
    return '/api';
  }

  // implement a default request method
  request(config) {
    return this.$http.request(config);
  }
  parameterNames() {
    return Object.assign(super.parameterNames(), {
      page: 'page[number]',
      limit: 'page[size]'
    });
  }
}
