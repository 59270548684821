var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    w-full\n    flex\n    items-center\n    md:justify-between\n    justify-between\n    md:items-center\n    bg-grey-darkest\n    z-10\n    md:pr-24\n  ",attrs:{"id":"main-header"}},[_c('nav',{staticClass:"navbar flex justify-between items-center",class:_vm.$lang == 'ar' ? 'w-3/5 xl:w-1/2 2xl:w-1/2' : 'w-1/2 lg:w-2/5 2xl:w-1/3'},[_c('div',{staticClass:"flex items-center md:w-1/5 ltr:mr-5 rtl:ml-5",class:_vm.$lang == 'ar' ? 'text-right' : 'text-left',attrs:{"id":"header-logo"},on:{"click":_vm.clickLogo}},[_c('router-link',{attrs:{"to":"/","exact":""}},[_c('picture',[_c('source',{attrs:{"srcset":require("@/assets/images/header/logo.png"),"media":"(min-width: 992px)"}}),_c('img',{staticClass:"logo w-full",attrs:{"src":require("@/assets/images/header/logo.png"),"alt":"logo","title":"Premium Card Logo"}})])])],1),(this.$route.name != 'passwordReset')?_c('ul',{staticClass:"md:flex hidden justify-between items-center md:w-4/5"},[_c('li',[_c('router-link',{staticClass:"md:text-13",class:_vm.$lang == 'ar'
              ? 'xl:text-xs 2xl:text-base'
              : 'xl:text-base 2xl:text-lg',attrs:{"to":{ name: 'home' },"title":"Home","exact":""},domProps:{"textContent":_vm._s(_vm.$t('header.home'))}})],1),_c('li',[_c('router-link',{staticClass:"md:text-13",class:_vm.$lang == 'ar'
              ? 'xl:text-xs 2xl:text-base'
              : 'xl:text-base 2xl:text-lg',attrs:{"to":{ name: 'network' },"title":"Stores Network"},domProps:{"textContent":_vm._s(_vm.$t('header.stores'))}})],1),_c('li',[_c('router-link',{staticClass:"md:text-13",class:_vm.$lang == 'ar'
              ? 'xl:text-xs 2xl:text-base'
              : 'xl:text-base 2xl:text-lg',attrs:{"to":{ name: 'offers/index' },"title":"Latest Offers"},domProps:{"textContent":_vm._s(_vm.$t('header.offers'))}})],1),_c('li',[_c('lang-option')],1)]):_vm._e()]),_c('div',{staticClass:"right-nav flex justify-end items-center",class:_vm.$lang == 'ar' ? 'w-2/5 xl:w-1/2 2xl:w-1/2' : 'w-1/2 lg:w-3/5 2xl:w-2/3'},[_c('sidenav'),(this.$route.name != 'passwordReset')?_c('SearchBox',{model:{value:(_vm.query.search),callback:function ($$v) {_vm.$set(_vm.query, "search", $$v)},expression:"query.search"}}):_vm._e(),(this.$route.name != 'passwordReset')?_c('button',{staticClass:"\n        bg-gold\n        text-white\n        px-4\n        hidden\n        md:block\n        ml-2\n        rounded-numbers\n        font-semibold\n        leading-6\n        md:text-13 md:w-36\n        header-btn\n      ",class:_vm.$lang == 'ar'
          ? 'xl:text-xs 2xl:text-base'
          : 'xl:text-base 2xl:text-lg'},[_c('router-link',{attrs:{"to":{ name: 'applyForCard' },"title":"Apply for Card"},domProps:{"textContent":_vm._s(_vm.$t('header.apply'))}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }