<template>
  <div class="text-xl" style="font-weight: 600">
    <div
      @click="showMenu = !showMenu"
      class="hidden md:flex items-center relative cursor-pointer"
    >
      <!-- <img src="@/assets/images/header/lang.svg" class="pb-1" alt=""> -->
      <div v-if="$lang == 'ar'" class="mx-2 xl:text-lg md:text-13 leading-none">أب</div>
      <div v-else class="mx-2 2xl:text-lg xl:text-base md:text-13 leading-none">EN</div>
      <img src="@/assets/images/header/downArrow.svg" alt="down arrow icon" />
      <div
        v-if="showMenu"
        class="absolute bg-grey-darker-1 top-1 left-0 w-20 p-4 text-center mt-3"
        style="box-shadow: 0 2px 4px 1px rgb(32 32 32 / 40%)"
      >
        <div
          @click="changeLang('ar')"
          :class="{ 'text-main': $lang == 'ar' }"
          class="mb-2"
        >
          أب
        </div>
        <div @click="changeLang('en')" :class="{ 'text-main': $lang == 'en' }">
          EN
        </div>
      </div>
    </div>
    <div class="md:hidden flex">
      <span
        @click="changeLang('en')"
        :class="{ 'text-main': $lang == 'en' }"
        class="cursor-pointer mr-1"
        >EN</span
      >
      /
      <span
        @click="changeLang('ar')"
        :class="{ 'text-main': $lang == 'ar' }"
        class="cursor-pointer ml-1"
        >أب</span
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    changeLang(lang) {
      this.$cookies.set("pc-lang", lang);
      window.location.reload();
    },
  },
};
</script>

<style>
</style>